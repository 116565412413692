import React, { Suspense, lazy, useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PusherSocket from './Components/PusherSocket';
import { I18nextProvider } from 'react-i18next';
import i18n from './Pages/i18n'; // Import i18n configuration
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import FirebaseError from './Components/FirebasError';
import { detectIncognito } from "detectincognitojs";
import MaintainenceScreen from './Components/MaintainenceScreen';

// Lazy load components
const ChatBox = lazy(() => import('./Pages/ChatBox'));
const Connect = lazy(() => import('./Pages/Connect'));
const SelectLanguage = lazy(() => import('./Pages/SelectLanguage'));
const SelectCategory = lazy(() => import('./Pages/SelectCategory'));
const Waiting = lazy(() => import('./Pages/Waiting'));
const VideoCall = lazy(() => import('./Pages/VideoCall'));
const Feedback = lazy(() => import('./Pages/Feedback'));
const Thankyou = lazy(() => import('./Pages/Thankyou'));
const SubCategory = lazy(() => import('./Pages/SubCategory'));
const ActionWeb = lazy(() => import('./Pages/ActionWeb'));
const CallConnect = lazy(() => import('./Pages/CallConnect'));
const Screensaver = lazy(() => import('./Pages/Screensaver'));

const App = () => {
  const [showError, setShowError] = useState(null); 
  const [isIncognito, setIsIncognito] = useState(false);

  useEffect(() => {
    const initializeFirebaseMessaging = async () => {
      detectIncognito().then(async (result) => {
        if (!result.isPrivate) {
          try {
            setShowError(false);
            if ('serviceWorker' in navigator && 'PushManager' in window) {
              var firebaseConfig = {
                apiKey: process.env.REACT_APP_WEB_PUSH_API_KEY,
                authDomain: process.env.REACT_APP_AUTH_DOMAIN,
                projectId: process.env.REACT_APP_PROJECT_ID,
                storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
                messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
                appId: process.env.REACT_APP_APP_ID,
                measurementId: process.env.REACT_APP_MEASUREMENT_ID
              };

              initializeApp(firebaseConfig);
              var messaging = getMessaging();

              const messageListener = () => {
                onMessage(messaging, (payload) => {
                  console.log("Message received:", payload);
                });
              };

              messageListener();
              const currentToken = await getToken(messaging, {
                vapidKey: process.env.REACT_APP_VAPID_KEY
              });

              if (currentToken) {
                sessionStorage.setItem('firebase_token', currentToken);
                console.log('Current token for client:', currentToken);
              } else {
                console.log('No registration token available. Request permission to generate one.');
              }
            } else {
              console.log('Firebase messaging is not supported in this browser.');
            }
          } catch (error) {
            console.log('An error occurred while initializing Firebase messaging:', error);
            const errVal = sessionStorage.getItem('notificationPopup');
            if (errVal !== 'true') {
              sessionStorage.setItem('notificationPopup', 'true'); 
              setTimeout(() => {}, 2000);
            }           
          }
        } else {
          const errVal = sessionStorage.getItem('notificationPopup');
          if (errVal !== 'true') {
            setIsIncognito(true);
            sessionStorage.setItem('notificationPopup', 'true'); 
            setTimeout(() => {}, 1000);
          }  
        }
      });
    };

    initializeFirebaseMessaging();
  }, []);

  if (showError) {
    return <FirebaseError isIncognito={isIncognito} />;
  }

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <PusherSocket />
        <Suspense>
          <Routes>
            <Route path="/" element={<Screensaver />} />
            <Route path="/Connect" element={<Connect />} />  
            <Route path="/languages" element={<SelectLanguage />} />  
            <Route path="/:langCode/categories" element={<SelectCategory />} />  
            <Route path="/:langCode/category/:categoryId/queue" element={<Waiting />} />
            <Route path="/call" element={<VideoCall />} />  
            <Route path="/chatBox" element={<ChatBox />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/thankyou" element={<Thankyou />} />
            <Route path="/:langCode/category/:categoryId/subCategories" element={<SubCategory />} />
            <Route path="/webView" element={<ActionWeb />} />
            <Route path="/callConnect" element={<CallConnect />} />
            <Route path="/maintenance" element={<MaintainenceScreen />} />
          </Routes>
        </Suspense>
      </Router>  
    </I18nextProvider>
  );  
};

export default App;
