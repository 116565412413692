import React, {useEffect} from 'react';

import Pusher from 'pusher-js';
import { useNavigate } from 'react-router-dom';
import ringtone from '../images/ringtone.mp3';

var mmData;

function PusherFunction() {
	const turnOffCamera = async () => {
		let localStream; // Declare localStream variable globally

			navigator.mediaDevices.getUserMedia({
			audio: true,
			video: true
			})
			.then(stream => {
			localStream = stream;
			setTimeout(() => {
				if (localStream) {
				localStream.getVideoTracks().forEach(track => {
					track.stop();
				});
				}
			}, 2000);
			})
			.catch((err) => {
			console.log(err);
			});
	  };

	var notificationSound = new Audio(ringtone);
	function handleUserAction() {
		notificationSound.play().then(() => {
			console.log("Playback successfully started");
		}).catch(error => {
			console.error("Playback failed:", error);
		});
	}
	  const playNotificationSound = () => {
        if (notificationSound.current) {
            const playPromise = notificationSound.current.play();
            if (playPromise !== undefined) {
                playPromise.then(_ => {
                    console.log("Playback successfully started");
                }).catch(error => {
                    console.error("Playback failed due to user interaction policy:", error);
                });
            }
        }
    };
	//This will be called when your component is mounted
    const navigate = useNavigate();
	useEffect(() => {
		const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
			cluster: process.env.REACT_APP_CLUSTER
		})
		const glabalChannel = pusher.subscribe(process.env.REACT_APP_KIOSK_ID);

		glabalChannel.bind('callAccepted',function(data) {
			// console.log('call accepted', new Date())
			
			const temp_Callid = sessionStorage.getItem('temp_CallId');
			if(temp_Callid == data.call_id) {
				console.log(data.call_id)
				sessionStorage.setItem('call_id', data.call_id);
				sessionStorage.setItem('isConnected', 'true')
				playNotificationSound();
				navigate('/callConnect');
			}
		})
		glabalChannel.bind('callEnded',function(data) {
			const temp_Callid1 = sessionStorage.getItem('temp_CallId');
			if(temp_Callid1 == data.call_id) {
				console.log(data.call_id)
				const getCallId = sessionStorage.getItem('call_id');
				const mmDataStatus = sessionStorage.getItem('mmStatus')
				if(getCallId == data.call_id) {
					turnOffCamera();	
					if(mmDataStatus == 1) {
						window.location.replace('/')
					}
					else {
						window.location.replace('/feedback')	
					}		
				}
			}
		})
		glabalChannel.bind('maintenanceMode',function(data) {
			console.log('maintenanceMode data:', data)
			mmData = data.maintenance_mode
			sessionStorage.setItem('mmStatus', mmData)
			sessionStorage.setItem('mmStatus', mmData)
			const currentPath = window.location.pathname;
			const pathSegments = currentPath.split('/');
			const lastSegment = pathSegments[pathSegments.length - 1];
			if(lastSegment == 'queue' || lastSegment == 'call') {
				const fetchLogoutApi = async () => {
					try {
					  const webToken = sessionStorage.getItem('uuid');
					  const token = sessionStorage.getItem('token');
					  const selLang = sessionStorage.getItem('selectedLanguage');
					  const tempID = sessionStorage.getItem('temp_CallId');
			  
					  const logoutUrl = `${process.env.REACT_APP_BASE_URL_API}passenger/logout`;
					  console.log(logoutUrl);
					  const logoutResponse = await fetch(logoutUrl, {
						  method: 'POST',
						  headers: {
							  'Content-Type': 'application/json',
							  'KIOSK-ID': process.env.REACT_APP_KIOSK_ID,
							  'DEVICE-ID': process.env.REACT_APP_DEVICE_ID,
							  'Authorization': `Bearer ${token}`,
							  'locale' :  `${selLang}`,
							  'WEB-TOKEN' : webToken
						  },
						  body: JSON.stringify({
							  'call_id' : tempID,
						  }),
					  });
					  
					  if (!logoutResponse.ok) {
						  const errorData = await logoutResponse.json(); 
						  let errorMessage = 'An unexpected error occurred';
						//   setApiError(true);
						  setTimeout(() => {
							// window.location.replace('/'); 
						  }, 1000);
						  if (logoutResponse.status === 400) {
							errorMessage = errorData.error.message;
							
						  } else if (logoutResponse.status === 401) {
							errorMessage = errorData.error.message
						  } 
						  else {
							setTimeout(() => {
								if(mmData == 1) {
									window.location.replace('/maintenance')
								}
								else {
									window.location.replace('/')
								}
							}, 500)
						  } 
						  return; 
						}
				  } catch (error) {
					  console.error('Error fetching data:', error);
				  }
				}
				fetchLogoutApi();
				setTimeout(() => {
					if(mmData == 1) {
						window.location.replace('/maintenance')
					}
					else {
						window.location.replace('/')
					}
				}, 1000)
			}
			else {
				if(mmData == 1) {
					window.location.replace('/maintenance')
				}
				else {
					window.location.replace('/')
				}
			}
				
		})
		return (() => {
		})
	}, []);


	return(
		<div>
			{/* Render Code here */}
		</div>
	)
}


export default PusherFunction;


