import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBellSlash, faSliders } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const FirebaseError = ({ isIncognito }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className='z-1 overlay absolute w-full h-full top-0'></div>
      <div className='absolute left-[50%] top-[50%] bg-[white] text-OyesterGrey flex-col font-bold flex items-center justify-center text-[20px] sm:w-auto w-[85%] py-[40px] px-[20px] sm:p-[50px] rounded-[15px] prompt z-2'>
          <FontAwesomeIcon icon={faBellSlash} style={{fontSize : '40px', marginBottom : '20px', color : 'red'}} />
          {isIncognito ? (
            <div className='incognito text-center'>
              <h2 className='sm:text-[30px] text-[20px] mb-2'>Notification not allowed in incognito mode</h2>
            </div>
          ) : (
            <div className='normal'>
              <h2 className='sm:text-[28px] text-[20px] mb-2'>Notification permission denied</h2>
              <ul className='list-decimal pl-[12px]'>
                <li className='text-[16px] sm:text-[20px] mt-[10px] text-[#666]'>Click the setting icon <span className='text-[16px] mb-[10px] px-2'><FontAwesomeIcon icon={faSliders} /></span> in your browser's address bar</li>
                <li className='text-[16px] sm:text-[20px] mt-[10px] text-[#666]'>{t('headings.permissionText4')}</li>
                <li className='text-[16px] sm:text-[20px] mt-[10px] text-[#666]'>{t('headings.permissionText3')}</li>
              </ul>
            </div>
          )}
      </div>  
    </>
  )
}

export default FirebaseError
