import React, { useEffect, useState } from 'react';
import loading from '../images/loader.gif'
import { useNavigate } from 'react-router-dom';

var fetchGeneralSetting;

const MaintainenceScreen = () => {
    const history = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const handleImageLoad = () => {
        setIsLoading(false); // Set isLoading to false when the image is fully loaded
    };
    fetchGeneralSetting = async () => {
        try {
          const webtoken = sessionStorage.getItem('uuid');
          const settingUrl = `${process.env.REACT_APP_BASE_URL_API}general-setting`;
          // console.log(settingUrl)
          const settingResponse = await fetch(settingUrl, {
            method: 'GET',
            headers: {
                'KIOSK-ID': process.env.REACT_APP_KIOSK_ID,
                'DEVICE-ID': process.env.REACT_APP_DEVICE_ID,
                'WEB-TOKEN' : webtoken  
            },
          });
    
          const settingData = await settingResponse.json();

    
          const mmUrl = settingData.data.company.maintenanceModeImage;
          const mmStatus = settingData.data.company.maintenance_mode;
          

          sessionStorage.setItem('mmUrl', mmUrl)
          sessionStorage.setItem('mmStatus', mmStatus)
        //   console.log('mmStatus', mmStatus)
          if(mmStatus == 0) {
            history('/') 
          }
          else {
            setIsLoading(false)
          }
        } catch (error) {
        }
      };
    
    useEffect(() => {
        fetchGeneralSetting();
    }, [])
    useEffect(() => {
        function handleVisibilityChange() {
          if (isMobileOrTablet()) {
            if (document.visibilityState === 'visible' || document.visibilityState === 'hidden') {
              history('/');
            }
          }
        }
      
        function isMobileOrTablet() {
          const userAgent = navigator.userAgent.toLowerCase();
          return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
        }
      
        document.addEventListener("visibilitychange", handleVisibilityChange);
      
        return () => {
          document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
      }, []);
    return (
        <>
            <div className='h-dvh'>
                {isLoading && (
                    <div className="flex items-center justify-center h-full">
                        <img src={loading} className='w-[50px] sm:w-[70px]' alt="loader" />
                    </div>
                )}
                    <div className='flex items-center justify-center h-full'>
                        <img
                            src={sessionStorage.getItem('mmUrl')}
                            alt="Maintenance mode"
                            className='sm:h-full h-auto'
                            onLoad={handleImageLoad} // Call handleImageLoad when the image is loaded
                        />
                    </div>
            </div>
        </>
    );
};

export default MaintainenceScreen;
